import { Meta } from '../typography';

import { Styled } from './form-headline.styles';

type FormHeadlineProps = {
  headlineMessage: React.ReactNode;
  subheadlineMessage?: React.ReactNode;
};

export const FormHeadline: React.FC<FormHeadlineProps> = ({
  headlineMessage,
  subheadlineMessage,
}) => {
  return (
    <Styled.HeadlineContainer>
      <Styled.Headline size="xlarge" sizeConfined="xlarge">
        {headlineMessage}
      </Styled.Headline>
      {subheadlineMessage ? (
        <Meta size="medium" sizeConfined="medium" noMargin>
          {subheadlineMessage}
        </Meta>
      ) : null}
    </Styled.HeadlineContainer>
  );
};
