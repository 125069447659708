import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Checkbox } from '@xing-com/checkbox';
import {
  PasswordInput,
  FormMessage,
  ContinueWithApple,
  ContinueWithGoogle,
  Divider,
  FormHeadline,
  PasswordStrengthMeter,
} from '@xing-com/crate-login-layout';
import { useLoginTracking } from '@xing-com/crate-login-tracking';
import { getMainHost, getExtraParams } from '@xing-com/crate-login-utils';
import { useHost, useLoginAppUrl } from '@xing-com/crate-xinglet';
import { FormField } from '@xing-com/text-field';

import { Recaptcha, RegisterViaCvSection } from './components';
import { RECAPTCHA_KEY, RECAPTCHA_KEY_XB } from './constants';
import messages from './messages';
import {
  FormContainer,
  FakeInput,
  InputActions,
  InputWrapper,
  SubmitButton,
  Label,
  HiddenWrapper,
  CheckboxWrapper,
  TandC,
} from './registration-form.styled';
import type { RegistrationFormProps } from './types';
import useFormActions from './use-form-actions';

export const RegistrationForm: FC<RegistrationFormProps> = ({
  hideHeadline,
  headlineMessage,
  subheadlineMessage,
  onSubmitSuccess,
  externalSubmit,
  onError,
  emailOnly,
  neededFields = ['firstName', 'lastName', 'email', 'password', 'tandcCheck'],
  handleBlur,
  handleChange,
  submitAfterRecaptcha = true,
  ctaMessage,
  signupChannel,
  cvUploadConfig,
  enableGoogleSignup,
  enableAppleSignup,
  prefilledValues = {},
}) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const extraParams = getExtraParams(urlParams);

  const loginPath =
    useLoginAppUrl() + `?signup_channel=${signupChannel}${extraParams}`;

  const host = useHost();
  const mainHostUrl = getMainHost(host.getHostname());
  const isXingbox =
    mainHostUrl.includes('kenv.xing.com') ||
    mainHostUrl.includes('oenv.xing.com');
  const GOOGLE_RECAPTCHA_SITE_KEY = isXingbox
    ? RECAPTCHA_KEY_XB
    : RECAPTCHA_KEY;

  const { trackPageView } = useLoginTracking();
  const { formatMessage, locale } = useIntl();
  const {
    isDisabled,
    formErrors,
    values,
    setValues,
    handleResetCvUpload,
    handleParsedCvDataReceived,
    maybeError,
    translateError,
    handleInputChange,
    handleEmailChange,
    showRecaptcha,
    onSubmit,
    isSubmitting,
  } = useFormActions({
    signupChannel,
    handleChange,
    prefilledValues,
    neededFields,
    emailOnly,
    externalUrl: mainHostUrl,
    submitAfterRecaptcha,
    onSubmitSuccess,
    externalSubmit,
    onError,
  });

  return showRecaptcha ? (
    <Recaptcha
      setValues={setValues}
      googleSiteKey={GOOGLE_RECAPTCHA_SITE_KEY}
      language={locale}
    />
  ) : (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      data-testid="registration-form"
    >
      {!hideHeadline ? (
        <FormHeadline
          headlineMessage={formatMessage(
            headlineMessage || messages.registrationHeadline
          )}
          subheadlineMessage={
            subheadlineMessage ? (
              formatMessage(subheadlineMessage)
            ) : (
              <FormattedMessage
                {...messages.registrationSubheadline}
                values={{
                  signin: (
                    <a href={loginPath} target="_blank" rel="noreferrer">
                      <FormattedMessage {...messages.loginHeadline} />
                    </a>
                  ),
                }}
              />
            )
          }
        />
      ) : null}

      {enableGoogleSignup && !cvUploadConfig ? (
        <ContinueWithGoogle
          signupChannel={
            signupChannel ? `${signupChannel}_oauth_google` : undefined
          }
          onClick={() => {
            trackPageView({
              page: 'signup/google',
              PropTrackAction: 'lp_google_click',
            });
          }}
        />
      ) : null}
      {enableAppleSignup && !cvUploadConfig ? (
        <ContinueWithApple
          signupChannel={
            signupChannel ? `${signupChannel}_oauth_apple` : undefined
          }
          onClick={() => {
            trackPageView({
              page: 'signup/apple',
              PropTrackAction: 'lp_apple_click',
            });
          }}
        />
      ) : null}

      {cvUploadConfig &&
      cvUploadConfig.source &&
      cvUploadConfig.flowIdentifier ? (
        <RegisterViaCvSection
          handleParsedCvDataReceived={handleParsedCvDataReceived}
          handleResetCvUpload={handleResetCvUpload}
          cvUploadConfig={cvUploadConfig}
        />
      ) : enableAppleSignup || enableGoogleSignup ? (
        <Divider>
          <FormattedMessage {...messages.separator} />
        </Divider>
      ) : null}

      <div>
        <FakeInput name={'email'} aria-hidden tabIndex={-1} />
        <FakeInput
          type={'password'}
          name={'password'}
          aria-hidden
          tabIndex={-1}
        />
        {!emailOnly && neededFields.includes('firstName') && (
          <InputWrapper>
            <FormField
              size="medium"
              error={Boolean(maybeError('firstName'))}
              helperText={maybeError('firstName')}
              name={'firstName'}
              id={'firstName'}
              // @ts-expect-error FIXME: SC6
              onBlur={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleBlur?.(e)
              }
              onChange={handleInputChange}
              placeholder={formatMessage(messages.firstNameLabel)}
              value={values?.firstName}
              data-testid={'firstName'}
            />
          </InputWrapper>
        )}
        {!emailOnly && neededFields.includes('lastName') && (
          <InputWrapper>
            <FormField
              size="medium"
              error={Boolean(maybeError('lastName'))}
              helperText={maybeError('lastName')}
              placeholder={formatMessage(messages.lastNameLabel)}
              name={'lastName'}
              id={'lastName'}
              // @ts-expect-error FIXME: SC6
              onBlur={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleBlur?.(e)
              }
              onChange={handleInputChange}
              value={values?.lastName}
              data-testid={'lastName'}
            />
          </InputWrapper>
        )}
        {cvUploadConfig &&
          cvUploadConfig.source &&
          cvUploadConfig.flowIdentifier &&
          values?.parsedCvId && (
            <HiddenWrapper>
              <FormField
                size="medium"
                name={'parsedCvId'}
                id={'parsedCvId'}
                onChange={handleInputChange}
                value={values.parsedCvId}
                data-testid={'parsedCvId'}
              />
            </HiddenWrapper>
          )}
        {neededFields.includes('email') && (
          <InputWrapper>
            <FormField
              size="medium"
              error={Boolean(
                maybeError('email') ||
                  (formErrors.emailDomainError ??
                    translateError(formErrors.emailDomain))
              )}
              helperText={
                maybeError('email') ||
                (formErrors.emailDomainError &&
                  translateError(formErrors.emailDomain)) ||
                undefined
              }
              name={'email'}
              id={'email'}
              type="email"
              // @ts-expect-error FIXME: SC6
              onBlur={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleBlur?.(e)
              }
              onChange={handleEmailChange}
              placeholder={formatMessage(messages.emailLabel)}
              value={values?.email}
              data-testid={'email'}
            />
          </InputWrapper>
        )}
        {neededFields.includes('password') && (
          <InputWrapper>
            <PasswordInput
              medium
              // @ts-expect-error FIXME: SC6
              error={maybeError('password')}
              name={'password'}
              id={'password'}
              // @ts-expect-error FIXME: SC6
              onBlur={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleBlur?.(e)
              }
              onChange={handleInputChange}
              placeholder={formatMessage(messages.passwordLabel)}
              value={values?.password}
              data-testid={'password'}
            />
            <PasswordStrengthMeter
              password={values?.password || ''}
              error={maybeError('password')}
            />
          </InputWrapper>
        )}
        {neededFields.includes('gRecaptchaResponse') && (
          <Recaptcha
            setValues={setValues}
            googleSiteKey={GOOGLE_RECAPTCHA_SITE_KEY}
            language={locale}
          />
        )}

        <>
          <FormMessage error={translateError(formErrors?.base)} />
        </>
        {formErrors.emailDomainError && (
          <>
            <FormMessage notice={formatMessage(messages.emailDomainError)} />
            <InputWrapper>
              <Checkbox
                // @ts-expect-error FIXME: SC6
                checked={values?.skipEmailDomainCheck}
                name={'skipEmailDomainCheck'}
                id={'skipEmailDomainCheck'}
                onChange={(event) => {
                  setValues((values) => ({
                    ...values,
                    // @ts-expect-error FIXME: SC6
                    skipEmailDomainCheck: event.target.checked,
                  }));
                }}
              />
              <Label htmlFor={'skipEmailDomainCheck'}>
                <FormattedMessage
                  {...messages.skipEmailDomainCheck}
                  values={{ email: <b>{values?.email}</b> }}
                />
              </Label>
            </InputWrapper>
          </>
        )}
        {formErrors.secondaryEmailError ? (
          <InputWrapper>
            <FormMessage notice={formatMessage(messages.secondaryEmailError)} />
            <CheckboxWrapper>
              <Checkbox
                // @ts-expect-error FIXME: SC6
                checked={!values?.checkSecondaryFields}
                name={'checkSecondaryFields'}
                id={'checkSecondaryFields'}
                onChange={(event) => {
                  setValues((values) => ({
                    ...values,
                    // @ts-expect-error FIXME: SC6
                    checkSecondaryFields: !event.target.checked,
                  }));
                }}
              />
              <label htmlFor={'checkSecondaryFields'}>
                <FormattedMessage
                  {...messages.skipSecondaryEmailCheck}
                  values={{ email: <b>{values?.email}</b> }}
                />
              </label>
            </CheckboxWrapper>
          </InputWrapper>
        ) : null}
        <SubmitButton
          variant="primary"
          size="medium"
          loading={isSubmitting}
          type={'submit'}
          disabled={isDisabled}
          data-testid={'register-submit-button'}
        >
          {formatMessage(ctaMessage || messages.submitLabel)}
        </SubmitButton>

        {neededFields.includes('tandcCheck') ? (
          <InputActions>
            <TandC size="xsmall" sizeConfined="xsmall">
              <FormattedMessage
                {...messages.tandcDescription}
                values={{
                  tandc: (
                    <a
                      href="https://www.xing.com/terms"
                      target="'_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage {...messages.tandcLabel} />
                    </a>
                  ),
                  dataprotection: (
                    <a
                      href={formatMessage(messages.dataprotectionLink)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage {...messages.dataprotectionLabel} />
                    </a>
                  ),
                }}
              />
            </TandC>
          </InputActions>
        ) : null}
      </div>
    </FormContainer>
  );
};
