import { useMutation } from '@apollo/client';

import type {
  ParsedCvData,
  UseGetParsedCvDataReturnType,
} from '../../../types';

import { CreateCvUploadParsedCvMutationDocument } from './create-cv-upload-parsed-cv-mutation.gql-types';

const useGetParsedCvData = (): UseGetParsedCvDataReturnType => {
  const [createParsedCv] = useMutation(CreateCvUploadParsedCvMutationDocument);

  const getParsedCvData = async (
    uploadId: string,
    source: string,
    flowIdentifier: string
  ): Promise<ParsedCvData | undefined> => {
    if (!uploadId || !source || !flowIdentifier) {
      // we do not have all the required fields
      return;
    }

    const { data } = await createParsedCv({
      variables: { uploadId, source, flowIdentifier },
    });

    if (data?.createCvUploadParsedCv?.__typename === 'CvUploadError') {
      // we did not receive a parsedCvID -> something went wrong
      return;
    }

    let parsedData: ParsedCvData = {
      id:
        data?.createCvUploadParsedCv?.__typename === 'CvUploadParsedCv'
          ? data?.createCvUploadParsedCv?.id
          : null,
      firstName: '',
      lastName: '',
      email: '',
    };

    if (data?.createCvUploadParsedCv?.__typename === 'CvUploadParsedCv') {
      // extract personal data if available
      if (data?.createCvUploadParsedCv?.textkernelPayload?.personal) {
        const {
          emails,
          firstName = '',
          lastName = '',
        } = data.createCvUploadParsedCv.textkernelPayload.personal;

        let email = null;
        if (emails && emails[0]) {
          if (emails[0].email) {
            email = emails[0].email;
          } else if (typeof emails[0] === 'string') {
            email = emails[0];
          }
        }

        parsedData = {
          ...parsedData,
          firstName,
          lastName,
          email,
        };
      }
    }

    return parsedData;
  };

  return { getParsedCvData };
};

export default useGetParsedCvData;
